import { POST } from "../method.js";
import { ENDPOINTS } from "../constants.js"

export const sendOTP = async (mobileNumber, countryCode, email) => {
	const url = ENDPOINTS.OTP_VALIDATION

	const data = {
		mobile_number: mobileNumber,
		country_code: `+${countryCode}`,
	};

	if (email) {
		data.email = email
	}

	return POST(url, data);
};

export const resendOTP = async (mobileNumber, countryCode, email) => {
	const url = ENDPOINTS.RESEND_OTP

	const data = {
		mobile_number: mobileNumber,
		country_code: `+${countryCode}`,
		type: "text"
	};

	if (email) {
		data.email = email
	}

	return POST(url, data);
};

export const loginAPI = async (mobileNumber, countryCode, otp, email) => {
	const url = ENDPOINTS.LOGIN;

	const data = {
		mobile_number: mobileNumber,
		country_code: `+${countryCode}`,
		otp: otp,
	};

	if (email) {
		data.email = email
	}

	return POST(url, data);
};
